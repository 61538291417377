<template>
	<div>

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

      <div class="main-content">
        <div class="row">
          <div class="col-md-12">
              <b-button class="btn btn-primary float-right ml-5" v-b-modal.view-cug>View CUG</b-button>
              <b-button class="btn btn-info float-right ml-5" v-b-modal.transfer-epurse>Transfer Epurse Value</b-button>
              <button class="btn btn-success float-right" @click="downloadCustomerStatement">Download Statement</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5>Customer Details</h5>
            <table class="table table-bordered">
              <tr>
                <th>Name: <span style="float: right;color:#4d4d4d;">{{ customerDetails.name  }}</span> </th>
                <th>Date added: <span style="float: right;color:#4d4d4d;">{{ customerDetails.created}}</span></th>
                <th>Status: <span style="float: right;color:#4d4d4d;">{{ customerDetails.status }} </span> </th>
              </tr>
              <tr>
                <th>Email Address: <span  style="float: right;color:#4d4d4d;">{{ customerDetails.email  }}</span></th>
                <th>Phone Number(s): <span style="float: right;color:#4d4d4d;">{{ customerDetails.mobileNumber  }}  <img src="@/assets/images/checked.png" style="height: 15px;width: 15px;" /> </span> <!--<span v-if="customerDetails.customerPhoneNumbers.length > 0"> / </span> {{ additional_phone_numbers }} <b-button v-if="customerDetails.customerPhoneNumbers.length > 0" class="btn btn-info btn-sm " v-b-modal.swap-phone-number>Swap</b-button></span>--> </th>
                <th>Loyalty Id: <span style="float: right;color:#4d4d4d;">{{ customerDetails.loyaltyId  }}</span> </th>
              </tr>
              <tr>
                <th>DoB: <span  style="float: right;color:#4d4d4d;">{{ dob  }}</span></th>
                <th>Gender: <span  style="float: right;color:#4d4d4d;">{{ customerDetails.gender  }}</span></th>
                <th >Address: <span style="float: right;color:#4d4d4d;">{{ customerDetails.address  }} </span></th>
              </tr>
              <tr>
                <th>Tier:  &nbsp;&nbsp;<b-button class="btn btn-sm btn-warning float-right " v-b-modal.change-tier>Update</b-button>
                  <span v-if="customerDetails.customerTier && customerDetails.customerTier == 'STANDARD'" style="float: right;" class="badge badge-primary">{{ customerDetails.customerTier }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <span v-if="customerDetails.customerTier && customerDetails.customerTier == 'PREMIUM'" style="float: right;" class="badge badge-success">{{ customerDetails.customerTier }}</span> &nbsp;&nbsp;
                </th>
                <th colspan="2">National ID:
                  <span  style="float: right;color:#4d4d4d;">
                      <span v-if="customerDetails.nationalID">
                        {{ customerDetails.nationalID  }}
                        <span v-if="customerDetails.nationalIdStatus == 'VERIFIED'"><img src="@/assets/images/checked.png" style="height: 15px;width: 15px;" /></span>
                        <span v-else>
                          <span class="badge badge-warning"> {{ customerDetails.nationalIdStatus }}</span>
                          <span v-if="customerDetails.nationalIDUrl"> <a style="text-decoration: underline;" target="_blank"  :href="customerDetails.nationalIDUrl"> View </a></span>
                          <b-button class="btn btn-sm btn-warning float-right " v-b-modal.verify-identification>Verify</b-button>
                        </span>
                      </span>
                      <span>
                        N/A
                      </span>
                  </span>
                </th>

              </tr>

            </table>
          </div>
        </div>

        <br/>
        <div class="row">
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-warning p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-clipboard text-warning font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">KES. {{ userStats.cashValue}}</h2>
                <p class="mb-0"><strong>Wallet balance</strong></p>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-primary p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-bar-chart text-primary font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">{{ userStats.totalPoints }}</h2>
                <p class="mb-0"><strong>Points balance</strong></p>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-warning p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-box text-warning font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">KES. {{ userStats.totalPointsRedeemed}}</h2>
                <p class="mb-0"><strong>Total Value Redeemed </strong></p>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-warning p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-users text-warning font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">{{ userStats.totalPointsAccrued}}</h2>
                <p class="mb-0"><strong>Total Points Accrued</strong></p>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-warning p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-clipboard text-warning font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">{{ userStats.totalPointsAccruedToday}}</h2>
                <p class="mb-0"><strong>Points Accrued Today</strong></p>
              </div>

            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="card">
              <div class="card-header d-flex flex-column align-items-start pb-0">
                <div class="avatar bg-rgba-warning p-50 m-0">
                  <div class="avatar-content">
                    <i class="feather icon-clipboard text-warning font-medium-5"></i>
                  </div>
                </div>
                <h2 class="text-bold-700 mt-1 mb-25">KES. {{ userStats.totalPointsRedeemedToday}}</h2>
                <p class="mb-0"><strong>Redemption Value Today</strong></p>
              </div>

            </div>
          </div>

        </div>

        <br/>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-bordered">
              <thead class="thead-dark">
              <tr>
                <th>Transactions </th>
              </tr>
              </thead>
            </table>
          </div>
        </div>

        <br/>

        <div class="row">
          <div class="col-md-5">
            <div>
              <strong>By date:</strong>
            </div>

            <date-picker   :lang="lang" v-model="start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker   :lang="lang" v-model="end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
            <button type="button" class="btn btn-danger" @click="getTransactionsData">Filter</button>
          </div>
          <div class="col-md-2">
            <div>
              <strong>By Outlet:</strong>
            </div>
            <select class="form-control" v-model="outlet" @change="getTransactionsData">
              <option value="" > All </option>
              <option :value="shop.id" v-for="shop in outlets"> {{ shop.name }}</option>
            </select>
          </div>
          <div class="col-md-2">
            <div>
              By Transaction type:
            </div>
            <select class="form-control" v-model="transaction_type" @change="getTransactionsData">
              <option value="all"> All </option>
              <option :value="t_type" v-for="t_type in transaction_types">{{ t_type }}</option>
            </select>
          </div>

        </div>
        <br/>

        <b-table striped hover :items="transactions" :fields="headers">
          <template v-slot:cell(customer)="data">
            <span>{{ data.value.name }}</span>
          </template>

          <template v-slot:cell(outlet)="data">
            <span>{{ data.value.name }}</span>
          </template>
          <template v-slot:cell(amount)="data">
            <span>KES. {{ data.value }}</span>
          </template>
          <template v-slot:cell(loyaltyPoints)="data">
            <span v-if="data.item.transactionType == 'ACCRUAL'"> {{ data.value }}</span>
          </template>
          <template v-slot:cell(transactionType)="data">
        <span  class="badge badge-danger"  v-if="data.value == 'ACCRUAL'">
							Accrual
						</span>
            <span class="badge badge-success"  v-else >
							Redemption
        </span>
          </template>

            <template v-slot:cell(transactionApprovalStatus)="data">
              <div v-if="data.value != null" >
                <span  class="badge badge-success"  v-if="data.value == 'APPROVED'" >
                  Accepted
                </span>
                <span  class="badge badge-warning"  v-else-if="data.value == 'PENDING'" >
                  Pending
                </span>

                <span class="badge badge-danger"  v-else >
                  Reversed
                </span>
              </div>
              <div v-else>
                <span   class="badge badge-info" >
                  Accepted
                </span>
              </div>
            </template>
        </b-table>

        <div class="row">
          <div class="col-md-12">

            <nav aria-label="Page navigation float-right" style="margin-top: 20px;">
              <ul class="pagination justify-content-center">
                <li v-if="pagination.current_page > 1" class="page-item"><a @click.prevent="changePage(pagination.current_page - 1)" class="page-link" href="#">Previous</a></li>
                <li v-for="page in pagesNumber"
                    v-bind:class="[ page == isActived ? 'active' : '']" class="page-item"><a @click.prevent="changePage(page - 1)" class="page-link" href="#">{{ page }}</a></li>
                <li v-if="pagination.current_page < pagination.last_page"  class="page-item"><a  @click.prevent="changePage(pagination.current_page + 1)"class="page-link" href="#">Next</a></li>
              </ul>
            </nav>
          </div>
        </div>

      </div>

        <b-modal id="change-tier" title="Change Tier" @cancel="true" @ok="submitCustomerTier"   centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">
              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label ">Tiers:</label> <br/>
                    <div >
                      <select class="form-control" v-model="customerDetails.customerTier">
                        <option value=""> -- Select -- </option>
                        <option value="STANDARD">Standard</option>
                        <option value="PREMIUM">Premium</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>

    <b-modal id="view-cug" title="Closed User Group Members" @cancel="true" size="lg"  centered>
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered table-sm">
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Date Joined</th>
              <th>Loyalty Points</th>
              <th>Epurse</th>
              <th>Status</th>
              <th>Options</th>
            </tr>
            <tr v-for="CUGMember in CUGMembers">
              <td>{{ CUGMember.name }}</td>
              <td>{{ CUGMember.mobileNumber }}</td>
              <td>{{CUGMember.dateJoinedCUG }}</td>
              <td>{{ CUGMember.loyaltyPoints }}</td>
              <td>{{ CUGMember.epurseBalance }}</td>
              <td>
                <span  class="badge badge-success"  v-if="CUGMember.admin">
                   Admin
                </span>
                <span class="badge badge-warning"  v-else >
                  Member
                </span>
              </td>
              <td>
                <button @click="openRemoveCUGMember(CUGMember)" class="btn btn-danger btn-sm" type="button"> Remove </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </b-modal>

      <b-modal id="verify-identification" title="Verify Identification" @cancel="true" @ok="submitVerifyCustomerId"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">
            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Status:</label> <br/>
                  <div >
                    <select class="form-control" v-model="customerDetails.nationalIdStatus">
                      <option value=""> -- Select -- </option>
                      <option value="VERIFIED">Verified</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="PENDING">Pending</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </b-modal>

          <b-modal id="transfer-epurse" title="Transfer Epurse Value" hide-footer   centered>
            <div class="mt-5 mb-5">
              <form-wizard ref="transferEpurse" stepSize="sm" title="" subtitle="" finishButtonText="Confirm" action="#" v-on:submit.prevent="transferEpurseBalance" @on-complete="transferEpurseBalance" @onNextStep="nextStep" @onPreviousStep="previousStep">
                <tab-content title="Step 1: Phone Number" :before-change="sendVerificationCodeToCustomer"
                             icon="ti-user" :selected="true" >
                  <div class="form-group ">
                    <label :class="{ 'text-danger': $v.transferEpurse.phoneNumber.$error }">Enter Phone number:</label> <br/>
                    <div >
                      <input v-model.trim="$v.transferEpurse.phoneNumber.$model" class="form-control" type="text" />
                      <div class="text-danger" v-if="!$v.transferEpurse.phoneNumber.required && $v.transferEpurse.phoneNumber.$dirty">Phone number is required</div>
                    </div>
                  </div>
                </tab-content>
                <tab-content title="Step 2: Verification Code" :before-change="verifyVcode"
                             icon="ti-user" >

                  <div class="mt-5 mb-5">
                    <div class="d-flex justify-content-center">
                      <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
                    </div>
                  </div>
                </tab-content>
                <tab-content title="Step 3: Amount"
                             icon="ti-user"  >
                  <div >
                    <div class="form-group ">
                      <label :class="{ 'text-danger': $v.transferEpurse.amount.$error }">Amount:</label> <br/>
                      <div >
                        <input v-model.trim="$v.transferEpurse.amount.$model" class="form-control" type="text"   />
                        <div class="text-danger" v-if="!$v.transferEpurse.amount.required && $v.transferEpurse.amount.$dirty">Amount field is required</div>

                      </div>
                    </div>
                    <div class="form-group ">
                      <label>Account Status: </label> <br/>
                      <label>
                        <input type="radio" name="accountStatus" v-model="transferEpurse.status" value="ACTIVE"> Active
                      </label>
                      <br/>
                      <label>
                        <input type="radio" name="accountStatus" v-model="transferEpurse.status" value="INCOMPLETE"> Incomplete
                      </label>
                      <br/>
                      <label>
                        <input type="radio" name="accountStatus" v-model="transferEpurse.status" value="INACTIVE"> Inactive
                      </label>

                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </div>

          </b-modal>

        <b-modal id="swap-phone-number" title="Swap Phone Number" @cancel="true" @ok="activateCustomerPhoneNumber"   centered>
          <form action="#" class="form-horizontal"  method="post">
            <input type="hidden" name="_token" />
            <div class="form-body">
              <div class="row" >
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="control-label "><strong>Select phone number to activate:</strong></label> <br/>
                    <div >
                      <ul style="list-style-type: none;">
                        <li v-for="customerPhoneNumber in customerDetails.customerPhoneNumbers">
                          <label :for="customerPhoneNumber.id">
                            <input  type="radio" name="swap_phone_number" :value="customerPhoneNumber.phoneNumber" :id="customerPhoneNumber.id"  v-model="phoneNumber" /> {{ customerPhoneNumber.phoneNumber}}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>

          <b-modal id="add-verification-code" title="Verification code" @cancel="true" @ok="verifyCode"   centered>
            <form action="#" autocomplete="off" class="form-horizontal"  method="post">

              <div class="text-center">
                <h1 class="mb-3 text-18 " >Please enter verification code </h1>
                <br/>
                <div class="d-flex justify-content-center">
                  <CodeInput  :fields="4" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
                </div>

              </div>

            </form>
          </b-modal>
	</div>
</template>
<script >

	import DatePicker from 'vue2-datepicker';
  import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
	import 'vue2-datepicker/index.css';
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import { required,requiredIf} from 'vuelidate/lib/validators'

  import CodeInput from "vue-verification-code-input"

  import { mapGetters, mapActions } from "vuex";

	export default {
		name: 'customer-profile',
		data () {
			return {
              phoneNumberVerified: false,
			  transferEpurse:{
			    phoneNumber:"",
                customerId:"",
                amount:"",
                status:"ACTIVE"
              },
			  phoneNumber:"",
              isLoading: false,
              fullPage: true,
				dialog_delete_tag: false,
				customerTag:{},

              phone_verified: false,
				customerDetails:{
					name: "",
					address: "",
					mobileNumber: "",
					email: "",
					gender: "",
					dob: "",
					loyaltyId: "",
					status: "",
					registeredBy: "",
					stationRegistered: "",
					password: "",
					tags: "",
                    customerTier:"",
                    nationalIdStatus:"",
                    nationalIDUrl:"",
                  nationalID:"",
                    customerPhoneNumbers:[]
				},
				station:{},
				product:{},
				userStats:{
					totalPoints:0,
					totalPointsRedeemed:0,
					totalPointsAccrued:0,
					totalPointsRedeemedToday:0,
					totalPointsAccruedToday:0,
          cashValue:0,
				},
				lang: {
					days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
					months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
					pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
					placeholder: {
						date: 'Select Date',
						dateRange: 'Select Date Range'
					}
				},
        start_date:"all",
        end_date:"all",
        outlets:[],
        pagination: {
          total: 0,
          per_page: 2,
          from: 1,
          to: 0,
          current_page: 0
        },
        offset: 4,
        outlet:0,
        transaction_type:"all",
				dialog_add_user: false,
				headers:['outlet','amount','transactionDate','loyaltyPoints','transactionType','transactionApprovalStatus','narration'],
				transactions:[],
				rowsPerPageItems: [50, 100, 150, 200],
        transaction_types:["Accrual","Redemption"],
        customerVerificationCode:"",
        verificationCode:"",
        CUGMembers:[]
			}

		},
        validations: {
          transferEpurse:{
            phoneNumber:{required},
            amount:{
              required: requiredIf(function () {
                return this.isPhoneNumberVerified
              })
            },
          }
        },

    computed:{
      isPhoneNumberVerified() {
        return  this.phoneNumberVerified
      },
      isActived: function () {
        return this.pagination.current_page + 1;
      },
      pagesNumber: function () {
        if (!this.pagination.to) {
          return [];
        }
        var from = this.pagination.current_page - this.offset;
        if (from < 1) {
          from = 1;
        }
        var to = from + (this.offset * 2);
        if (to >= this.pagination.last_page) {
          to = this.pagination.last_page;
        }
        var pagesArray = [];
        while (from <= to) {
          pagesArray.push(from);
          from++;
        }

        return pagesArray;
      },
      dob(){

           if(this.customerDetails.dob){
             let date_arr = this.customerDetails.dob.trim().split("T");

             if(date_arr.length > 0){
               return date_arr[0];
             }
             else{
               return "";
             }

           }
           else{
             return "";
           }

      },
      additional_phone_numbers(){
         let phone_numbers = " ";

         let self = this;

         if(this.customerDetails.customerPhoneNumbers.length > 0){

           let size = this.customerDetails.customerPhoneNumbers.length;



           let i = 0;

           this.customerDetails.customerPhoneNumbers.forEach(function (customerPhoneNumber){

              if(customerPhoneNumber.phoneNumber != self.customerDetails.mobileNumber ){
                if(phone_numbers){

                  if(i == size - 1){
                    phone_numbers += customerPhoneNumber.phoneNumber
                  }else{
                    phone_numbers += customerPhoneNumber.phoneNumber +" / "
                  }
                }
                else{
                  phone_numbers += customerPhoneNumber.phoneNumber
                }

                console.log(phone_numbers);
              }


              i++;
           });


         }

         return phone_numbers
      }
    },
		mounted (){
			//this.getTransactions();
			this.getCustomerSummaryData();
			this.getCustomerProfileData();
			this.getOutletData();
			this.getTransactionsData();
      this.getCUGMembersData();
		},
		components: {
          FormWizard,
          TabContent,
          Loading,
          DatePicker,
          CodeInput
		},
		methods: {

      ...mapActions(["removeCUGMember","getCUGMembers","updateCustomerTier","verifyCustomerIdentification","getCustomers", "getCustomerDetails", "getOutlets", "getCustomerSummary", "getCustomerTransactions", "downloadStatement", "transferCustomerEpurse","activatePhoneNumber","sendVerificationCode"]),

      getCUGMembersData(){
        let self = this;

        this.getCUGMembers({customerId: this.$route.params.id})
            .then(function (CUGMembers) {

              self.CUGMembers = CUGMembers;

            })
            .catch(function (error) {
              if (typeof error.response.status != "undefined") {
                if (error.response.status == 401) {
                  location.replace("/login");
                }

              }
            });
      },
          sendVerificationCodeToCustomer(){

            let self = this;
            return new Promise((resolve, reject) => {
              self.$v.$touch();
              if (!self.$v.$invalid) {
                self.isLoading = true;

                this.isLoading = true;

                self.sendVerificationCode({phoneNumber: self.transferEpurse.phoneNumber})
                        .then(function (verification) {
                          self.isLoading = false;

                          if(verification.customerExists){
                            self.verificationCode = verification.message;

                            resolve(true);
                          }
                          else{
                            reject({message:"Customer does not exist"});
                          }

                        })
                        .catch(function (error) {

                          self.isLoading = false;

                          reject({});

                        });
              }
            })
          },

      onChange(v) {

      },

          submitCustomerTier(){

            let self = this;

            this.isLoading = true;

            this.updateCustomerTier({customerTier: this.customerDetails.customerTier, customerId: this.customerDetails.id})
                    .then(function () {
                      self.isLoading = false;

                      self.$bvToast.toast("Customer tier updated", {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: false
                      });

                      self.getCustomerProfileData();

                    })
                    .catch(function (error) {

                      self.isLoading = false;

                      self.$bvToast.toast(error.message, {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000,
                        appendToast: false
                      });

                    })
          },

          submitVerifyCustomerId(){

            let self = this;

            this.isLoading = true;

            this.verifyCustomerIdentification({status: this.customerDetails.nationalIdStatus, customerId: this.customerDetails.id})
                    .then(function () {
                      self.isLoading = false;

                      self.$bvToast.toast("Customer identification updated", {
                        title: 'Success',
                        variant: 'success',
                        autoHideDelay: 5000,
                        appendToast: false
                      });

                      self.getCustomerProfileData();

                    })
                    .catch(function (error) {

                      self.isLoading = false;

                      self.$bvToast.toast(error.message, {
                        title: 'Error',
                        variant: 'danger',
                        autoHideDelay: 5000,
                        appendToast: false
                      });

                    })
          },

      onComplete(v) {
        this.customerVerificationCode = v;
      },
          verifyVcode(){
            let self = this;
            return new Promise((resolve, reject) => {
              if(self.verificationCode == self.customerVerificationCode){
                self.phoneNumberVerified = true;
                resolve(true);
              }
              else{
                reject({});
              }
            });
          },

      transferEpurseBalance(){

        let self = this;

        if(parseInt(this.transferEpurse.amount) >= 1){
          return new Promise((resolve, reject) => {

            self.$v.$touch();
            if (!self.$v.$invalid) {

              self.isLoading = true;
              this.transferEpurse.customerId = self.$route.params.id;

              self.transferCustomerEpurse(self.transferEpurse).then((response) => {
                self.isLoading = false;

                resolve(true);

                this.$bvModal.hide("transfer-epurse");

                self.$bvToast.toast("E-purse balance transferred", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.getCustomerProfileData();
                self.getTransactionsData();


              }).catch(error => {
                reject({});
                self.isLoading = false;
                console.log(error);
                self.$bvToast.toast(error.message, {
                  title: 'Error',
                  variant: 'danger',
                  autoHideDelay: 5000,
                  appendToast: false
                });
              })
            }
          });
        }
        else{
          this.$bvToast.toast("Transfer amount should be greater than or equal to 1", {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });
        }
      },

      activateCustomerPhoneNumber() {
        this.isLoading = true;

        let self = this;

        this.activatePhoneNumber({oldPhoneNumber: this.customerDetails.mobileNumber, newPhoneNumber: this.phoneNumber, id: this.customerDetails.id}).then(() => {
          self.isLoading = false;

          self.$bvToast.toast("Phone number activated.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });


          self.getCustomerProfileData();

        })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

            /*if( typeof error.response.status != "undefined"){
               if(error.response.status == 401){
                 location.replace("/login");
               }

            }
            else{




            }*/
          })
      },

      downloadCustomerStatement() {

        let self = this;

        this.isLoading = true;

        this.downloadStatement({
          customer_id: this.$route.params.id,
          start_date: this.start_date,
          end_date: this.end_date
        })
          .then(function (response) {

            self.isLoading = false;

            if (response.statusCode == 200) {
              location.replace(response.message)
            } else {
              self.$bvToast.toast(response.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }

          })
          .catch(function (error) {
            self.isLoading = false;
          });
      },


      changePage: function (page) {
        this.pagination.current_page = page;
        this.getTransactionsData();
      },

      openCustomerTagDeleteDialog(customerTag) {
        this.customerTag = customerTag;
        this.dialog_delete_tag = true;

      },

      getOutletData() {
        let self = this;


        this.getOutlets()
          .then(function (outlets) {

            self.outlets = outlets;

          })
          .catch(function (error) {
            if (typeof error.response.status != "undefined") {
              if (error.response.status == 401) {
                location.replace("/login");
              }

            }
          });
      },
      getCustomerProfileData() {

        let self = this;

        this.getCustomerDetails({customerId: this.$route.params.id})
          .then(function (customerDetails) {



            self.customerDetails = customerDetails;
          })
          .catch(function (error) {

            console.log(error);


            /* if( typeof error.response.status != "undefined"){
              if(error.response.status == 401){
                location.replace("/login");
              }

            }*/
          })
      },

      getCustomerSummaryData() {
        let self = this;


        this.getCustomerSummary({customerId: this.$route.params.id})
          .then(function (customerDetails) {

            self.userStats = customerDetails;
          })
          .catch(function (error) {

             if( typeof error.response.status != "undefined"){
               if(error.response.status == 401){

                 self.$router.push({ name: 'auth-login', query: { redirect: '/path' } });
               }

             }
          })
      },

      getTransactionsData() {

        //{}

        this.isLoading = true;

        let self = this;


        this.getCustomerTransactions({
          customerId: this.$route.params.id,
          page: this.pagination.current_page,
          from_date: this.start_date,
          to_date: this.end_date,
          outlet_id: this.outlet,
          transaction_type: this.transaction_type
        })
          .then(function (transactions) {

            self.isLoading = false;


            self.transactions = [];

            let transactions_arr = transactions.content;

            transactions_arr.forEach(function (transaction) {


              let single_loyalty_trans = transaction;

              if (transaction.customer != null) {
                single_loyalty_trans.phoneNumber = transaction.customer.mobileNumber;
              } else {
                single_loyalty_trans.phoneNumber = "";
              }


              self.transactions.push(single_loyalty_trans)
            });


            self.pagination.current_page = transactions.number;
            self.pagination.total = transactions.totalElements;
            self.pagination.per_page = transactions.numberOfElements;
            self.pagination.from = transactions.pageable.offset + 1;
            self.pagination.to = transactions.pageable.pageSize;
            self.pagination.last_page = transactions.totalPages;
          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error);


            /* if( typeof error.response.status != "undefined"){
               if(error.response.status == 401){
                 location.replace("/login");
               }

             }*/
          });

       /* this.$store.dispatch('transactions/getCustomerTransactions', {customerId: this.$route.params.id})
          .then((transactions) => {
            this.$vs.loading.close()

            this.transactions = transactions;


          })
          .catch(error => {

            console.log(error)
            this.$vs.loading.close()
          })*/
      },

      openRemoveCUGMember(CUGMember){
        let self = this;
        this.$bvModal.msgBoxConfirm('Remove '+CUGMember.name+" from CUG ?")
            .then(value => {

              if(value){
                self.confirmRemoveFromCUG(CUGMember);
              }

            })
            .catch(err => {
              // An error occurred
            })
      },
      confirmRemoveFromCUG(CUGMember){
        let self = this;

        this.isLoading = true;

        this.removeCUGMember({customerId: CUGMember.id })
            .then(function () {
              self.isLoading = false;

              self.$bvToast.toast("Customer removed successfully.", {
                title: 'Success',
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: false
              });

              self.getCUGMembersData();
            })
            .catch(function (error) {

              self.isLoading = false;

              self.$bvToast.toast(error.message, {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });

            })
      },


    }

	}

</script>

<style >

  .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
    background-color: transparent !important;
  }
  .vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
    color:#4AB5E6 !important;
  }


  .vue-form-wizard .wizard-tab-content {
    padding-top: 50px;
  }
  .stepTitle{
    margin-top: -67px;
    color:#4AB5E6;
    font-weight: 600;
  }
  .vue-form-wizard.sm .wizard-icon-circle{
    height: 20px;
    width: 20px;
    background-color: #4AB5E6;
    border: none;
  }
  .vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
    top:14px;
    left:15.5%;
    width: 69.5% !important;
    background-color: #4AB5E6 !important;
    height: 2px !important;
  }
  table tr th{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  fieldset.custom-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow: 0px 0px 0px 0px #000;
    box-shadow: 0px 0px 0px 0px #000;
  }

  legend.custom-border {
    width: auto;
    padding: 0 10px;
    border-bottom: none;
  }
  .vue-step-wizard{
    width: 100% !important;
  }
  .tabStatus{
    background-color: #002563 !important;
  }
  .tabLabel{
    font-weight: bolder;
  }

  label{
    font-size: 14px;
  }

  .vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
    width: 0% !important;
  }
  .vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
    background-color: #4AB5E6 !important;
    color:#000 !important;
    border: none;
    margin-top: 20px;

  }

  table td{
    padding: 10px;
  }
</style>
